import cx from 'classnames';
import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import CatList from '../../components/CategoryList/CatList';
import MdGraphicList from '../../components/GraphicsList/MdGraphicList';
import SEO from '../../components/seo';
import { usePageContext } from '../../contexts/pageContext';
import { useMdData } from '../../static-queries/useBlogData';
import styles from './graphics.module.scss';

type IGraphicsProps = {};

const Graphics: React.FC<IGraphicsProps> = ({}) => {
  const data = useMdData();
  const graphics = data.graphics.edges;

  const [headerOpacity, setHeaderOpacity] = useState(1);

  const handleScroll = () => {
    const distanceFromTop = window.scrollY;
    const newOpacity = Math.max(1 - distanceFromTop / 60, 0);
    setHeaderOpacity(newOpacity);
  };
  const {setIsSideBarShown, isSideBarShown} = usePageContext();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <div className={styles.background}></div>
      <article
        className={cx(
          'w-full px-6 pb-6 min-h-screen flex items-center flex-col',
          styles.mainContainer
        )}>
        <SEO title={'Visuals | Gordo Labs'} />
        <div
          className={cx('hidden md:block w-full', !isSideBarShown && 'pl-8')}>
          <CatList />
        </div>
        <section className={cx(styles.introSection, 'w-full')}>
          <h2
            className="font-gordo text-xl md:text-3xl tracking-wider rounded-2xl px-4 py-2  items-center flex gap-3 md:mt-0 mt-24"
            style={{opacity: headerOpacity}}>
            <span>⛋</span>
          </h2>
        </section>

        <section className={cx(styles.base)}>
          <MdGraphicList list={graphics} />
        </section>
      </article>
    </React.Fragment>
  );
};

Graphics.defaultProps = {};

export default Graphics;
