import React, { useEffect } from 'react';

type IPageFooterProps = {};

const defaultProps = {};

const PageFooter: React.FC<IPageFooterProps> = (props) => {
  const {} = props;

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <hr className="mb-10" />
      <footer
        className={
          'flex flex-col md:flex-row w-full pr-10 pb-5 gap-2 md:gap-4 items-center justify-center'
        }>
        <p className={'font-charisma tracking-wide text-xs text-gray-500'}>
          © Gordo-Labs
        </p>
        <p className={'font-charisma tracking-wide text-xs text-gray-500'}>
          gordo-d.eth
        </p>

        <a href="/studio">
          <p className={'font-charisma tracking-wide text-xs text-gray-500'}>
            Studio
          </p>
        </a>
        <a href="mailto:hello@gordo.design">
          <p className={'font-charisma tracking-wide text-xs text-gray-500'}>
            📬 hello@gordo.design
          </p>
        </a>
      </footer>
    </React.Fragment>
  );
};

PageFooter.defaultProps = defaultProps;

export default PageFooter;
