import cx from 'classnames';
import { Link } from 'gatsby';
import React, {
  useEffect,
  useState,
} from 'react';
import categoriesFile from '../../../content/data/categories.json';
import { useHorizontalScroll } from '../../hooks/uiSideScroll';
import { ICategory } from '../../models/categoryModel';
import CatElement from '../CatElement/Cat';
import styles from './catlist.module.scss';

type ICatListProps = {
  removeMenu?: boolean
};

const defaultProps = {};

const CatList: React.FC<
  ICatListProps
> = (props) => {
  const {removeMenu} = props;
  const scrollRef =
    useHorizontalScroll();
  const [catBoolList, setCatBoolList] =
    useState<any>();

  useEffect(() => {
    const newCatList =
      categoriesFile.categories.map(
        (e: ICategory, i) => {
          // console.log(e);
          e.isHover = false;
        }
      );
    console.log(
      'NEWLIST =>  ',
      newCatList
    );
    setCatBoolList(newCatList);
  }, []);

  useEffect(() => {
    // console.log(
    //   'CATlist => ',
    //   catBoolList
    // );
  }, [catBoolList]);

  return (
    <section
      className={
        'flex w-full items-center my-4'
      }>
      <div
        ref={scrollRef}
        className={cx(
          ' flex w-full px-2 align-center z-100',
          styles.hideScrollBar,
          removeMenu ? "border-b border-gray-200 pb-2" : "border-l border-r border-black"
        )}>
        {categoriesFile.categories.map(
          (cat: ICategory, i: any) => {
            return (
              <CatElement key={i} cat={cat} />
            );
          }
        )}
      </div>
      {!removeMenu && <div
        className={cx(
          'flex h-full items-center pl-4',
          styles.links
        )}>
        <Link
          className={
            'flex align-center'
          }
          to={'/projects'}>
          <p className="font-charisma text-xs px-2 py-1 rounded-md">
            ☆ Projects
          </p>
        </Link>
        <Link
          className={
            'flex align-center'
          }
          to={'/blog'}>
          <p className="font-charisma text-xs px-2 py-1 rounded-md ">
            ☱ Blog
          </p>
        </Link>
        <Link
          className={
            'flex align-center'
          }
          to={'/graphics'}>
          <p className="font-charisma text-xs px-2 py-1 rounded-md ">
            ⛋ Visuals
          </p>
        </Link>
      </div>}
    </section>
  );
};

CatList.defaultProps = defaultProps;

export default CatList;
