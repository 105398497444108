import { graphql, useStaticQuery } from 'gatsby';

export function useMdData() {
    const data = useStaticQuery<GatsbyTypes.getMdDataQuery>(graphql`
        query getMdData {
            posts: allMarkdownRemark(
                sort: { order: DESC, fields: frontmatter___date },
                limit: 2000,
                filter: {fileAbsolutePath: {regex: "/posts/.*\\.md$/"}}
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            date(formatString: "MMMM Do, YYYY")
                            author
                            title
                            categories
                            hero_image {
                                childImageSharp {
                                    fluid( maxWidth: 800 ) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        excerpt(pruneLength: 200)
                        fields {
                            slug
                            type
                        }
                    }
                }
            }
            
            projects: allMarkdownRemark(
                sort: { order: DESC, fields: frontmatter___date },
                limit: 2000,
                filter: {fileAbsolutePath: {regex: "/projects/.*\\.md$/"}}
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            date(formatString: "MMMM Do, YYYY")
                            author
                            title
                            categories
                            hero_image {
                                childImageSharp {
                                    fluid( maxWidth: 800 ) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        excerpt(pruneLength: 200)
                        fields {
                            slug
                            type
                        }
                    }
                }
            }

            graphics: allMarkdownRemark(
                sort: { order: DESC, fields: frontmatter___date },
                limit: 2000,
                filter: {fileAbsolutePath: {regex: "/graphics/.*\\.md$/"}}
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            date(formatString: "MMMM Do, YYYY")
                            author
                            title
                            categories
                            hero_image {
                                childImageSharp {
                                    fluid( maxWidth: 800 ) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        excerpt(pruneLength: 200)
                        fields {
                            slug
                            type
                        }
                    }
                }
            }
        }
    `);
  return data;
}
