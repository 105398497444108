import { Link } from 'gatsby';
import React from 'react';
import { SizeMe } from 'react-sizeme';
import StackGrid from 'react-stack-grid';
import graphicListStyles from './graphiclist.module.scss';

import cx from 'classnames';
import Img from 'gatsby-image';
import { useEffect, useState } from 'react';
import PageFooter from '../pageFooter/pageFooter';

function BuildImage(image: any) {
  const imageEl = image.hero_image;
  return !!imageEl ? (
    <Img
      className={cx(graphicListStyles.imageContainer)}
      fluid={imageEl.childImageSharp.fluid}
      alt={image.title}
    />
  ) : null;
}

const MdGraphicList = ({list}: any) => {
  const [blogData, setBlogData] = useState(list);
  console.log(blogData);

  useEffect(() => {
    setTimeout(() => {
      setBlogData([...blogData]);
    }, 400);
    return () => {};
  }, []);

  function renderBlogData() {
    return (
      <>
        <div className="pb-10">
          <SizeMe>
            {({size}) => {
              return (
                <StackGrid
                  appearDelay={100}
                  columnWidth={
                    size.width >= 640 && size.width <= 960
                      ? '50%'
                      : size.width <= 640
                      ? '100%'
                      : '33%'
                  }
                  gutterWidth={20}
                  gutterHeight={20}>
                  {blogData
                    .filter((blog) => blog.node.frontmatter.title !== '')
                    .map((blog) => {
                      return (
                        <div
                          className={cx(
                            graphicListStyles.post,
                            'font-charisma'
                          )}
                          key={blog.node.id}>
                          <Link
                            className="cursor-pointer"
                            to={`/${blog.node.fields.type}/${blog.node.fields.slug}`}>
                            <li
                              className={cx(graphicListStyles.element)}
                              key={blog.node.fields.slug}>
                              <div className={graphicListStyles.list__hero}>
                                {BuildImage(blog.node.frontmatter)}
                              </div>
                              <div className={graphicListStyles.list__info}>
                                <h2 className="text-xs text-gray-800 leading-snug">
                                  {blog.node.frontmatter.title}
                                </h2>
                                {/*<p className="pb-6 text-gray-600 tracking-wide">{blog.node.excerpt}</p>*/}
                                {/*                           <p className={cx(graphicListStyles.date, "text-gray-700 tracking-wide text-2xs")}>{blog.node.frontmatter.date}</p>*/}
                              </div>
                            </li>
                          </Link>
                        </div>
                      );
                    })}
                </StackGrid>
              );
            }}
          </SizeMe>
        </div>
        <PageFooter />
      </>
    );
  }

  return (
    <section>
      <div className={cx(graphicListStyles.mainContainer)}>
        {
          // setTimeout(el => {
          renderBlogData()
          // }, 100)
        }
      </div>
    </section>
  );
};

export default MdGraphicList;
