import {Link} from 'gatsby';
import React, {
  useState,
  useEffect,
} from 'react';
import {ICategory} from '../../models/categoryModel';

type ICatElementProps = {
  cat: ICategory,
};

const defaultProps = {};

const CatElement: React.FC<
  ICatElementProps
> = (props) => {
  const {cat} = props;
  const [catState, setCatState] =
    useState(cat);

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <Link
        className="mr-2 flex items-center"
        to={'/category/' + cat.id}>
        <p
          key={catState.id}
          className="font-charisma flex items-center whitespace-nowrap text-xs px-2 py-1 rounded-md hover:text-black text-grey-700"
          onMouseEnter={() => {
            setCatState({
              ...catState,
              isHover: true,
            });
          }}
          onMouseLeave={() => {
            setCatState({
              ...catState,
              isHover: false,
            });
          }}
          style={{
            whiteSpace: 'nowrap',
            backgroundColor:
              catState.color &&
              catState.isHover
                ? catState.color
                : 'white',
            boxShadow: !catState.color
              ? '0px, 2px, 7px black'
              : '0px',
          }}>
          {catState.name}
          <span className="font-charisma whitespace-nowrap text-xs pl-2 text-gray-600">
            {catState.count}
          </span>
        </p>
      </Link>
    </React.Fragment>
  );
};

CatElement.defaultProps = defaultProps;

export default CatElement;
